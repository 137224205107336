import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as walletActions from './store/actions/wallet';

import Layout from './components/Layout';
import Loader from './components/Loader';
import GlobalStyle from './globalStyles';
import 'antd/dist/antd.css';

const HomePage = React.lazy(() => import('./pages/Home'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

const App = ({ onChangeCahinId, onConnectWallet }) => {
  useEffect(() => {
    if (window.BinanceChain) {
      window.BinanceChain.on('chainChanged', chainId =>
        onChangeCahinId(chainId)
      );

      window.BinanceChain.on('accountsChanged', async accounts => {
        const accs = await window.BinanceChain.requestAccounts();
        const acc = accs.find(ac =>
          ac.addresses.find(addr => addr.address === accounts[0])
        );
        onConnectWallet(acc);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <GlobalStyle />
      <Router>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    </>
  );
};
const mapStateToProps = state => {
  return {
    wallet: state.wallet.address,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeCahinId: chainId => dispatch(walletActions.changeChainId(chainId)),
    onConnectWallet: accounts =>
      dispatch(walletActions.connectWallet(accounts)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
