import * as actionTypes from '../types/transactions';

const initialState = {
  form: {},
  formStep: 0,
  processing: false,
  sourceTxStatus: 'pending',
  destinationTxStatus: 'not started',
  sourceNetworkHash: '',
  destinationNetworkHash: '',
  errorMessage: '',
  fee: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORM:
      return {
        ...state,
        form: { ...action.form },
      };
    case actionTypes.SET_PROCESSING:
      return {
        ...state,
        processing: action.processing,
      };
    case actionTypes.SET_DESTINATION_TX_STATUS:
      return {
        ...state,
        destinationTxStatus: action.status,
      };
    case actionTypes.SET_SOURCE_TX_STATUS:
      return {
        ...state,
        sourceTxStatus: action.status,
      };
    case actionTypes.SET_SOURCE_NETWORK_HASH:
      return {
        ...state,
        sourceNetworkHash: action.hash,
      };
    case actionTypes.SET_DESTINATION_NETWORK_HASH:
      return {
        ...state,
        destinationNetworkHash: action.hash,
      };
    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.error,
      };
    case actionTypes.SET_FORM_STEP:
      return {
        ...state,
        formStep: action.step,
      };
    case actionTypes.SET_FEE:
      return {
        ...state,
        fee: action.fee,
      };
    default:
      return state;
  }
};

export default reducer;
