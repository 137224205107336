import React from 'react';
import Lottie from 'react-lottie-player';
import animation from './animation.json';
import StarWrapper from './StarAnimation.styled';

const StarAnimation = () => {
  return (
    <StarWrapper>
      <Lottie
        animationData={animation}
        background="transparent"
        speed={1}
        style={{ width: 200, height: 200 }}
        loop
        play
      />
    </StarWrapper>
  );
};

export default StarAnimation;
