import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import ui from './store/reducers/ui';
import wallet from './store/reducers/wallet';
import transactions from './store/reducers/transactions';
import App from './App';

const rootReducer = combineReducers({
  ui,
  wallet,
  transactions,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
