import styled from 'styled-components';
import device from '../../utils/devices';

export const Header = styled.header`
  position: relative;
  background: transparent;
  z-index: 20;
  height: 90px;
  width: 100vw;
`;

export const Container = styled.div`
  position: relative;
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const LogoLink = styled.div`
  display: flex;
  justify-content: start;
  & > img {
    display: block;
    margin: 0 auto;
  }
  @media ${device.tablet} {
    > img {
      width: 33px;
      height: 35px;
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0 0 18px;
  @media ${device.tablet} {
    margin: 0 0 0 10px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #ffffff;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 10px;
    letter-spacing: 0.1em;
  }
`;

export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  @media ${device.tablet} {
    font-size: 12px;
    letter-spacing: 0.05em;
  }
`;

export const Button = styled.button`
  width: 145px;
  height: 38px;
  background: #3d7bc2;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: start;
  cursor: pointer;
`;

export const WalletAddress = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  border: ${props => (props.isConnected ? '1px solid #3D7BC2' : 'none')};
  padding: 5px 10px;
  border-radius: 5px;

  & > button {
    margin-left: 12px;
  }
`;

export const Adress = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  & > img {
    margin-right: 10px;
  }
`;
