import styled from 'styled-components';
// import { Link as RouteLink } from 'react-router-dom';
import device from '../../utils/devices';

export const Footer = styled.footer`
  background: #fff;
  z-index: 2;
`;

export const Container = styled.div`
  position: relative;
  max-width: 1440px;
  width: 80%;
  padding: 2% 0;
  margin: 0 auto;
`;

export const DisclaimerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  &::after,
  &::before {
    content: '';
    display: inline-block;
    width: 48%;
    background: #aec3d7;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  &::after {
    right: 0.5%;
  }
  &::before {
    left: 0.5%;
  }

  @media ${device.tablet} {
    &::after {
      right: 0%;
    }
    &::before {
      left: 0%;
    }
  }

  @media ${device.mobileL} {
    &::after {
      right: -1%;
    }
    &::before {
      left: -1%;
    }
  }

  @media ${device.mobile} {
    &::after {
      right: -3.5%;
    }
    &::before {
      left: -3.5%;
    }
  }
`;

export const DisclaimerBtn = styled.button`
  display: block;
  z-index: 2;
  border-radius: 50%;
  border: none;
  background: transparent;
  margin: 0 auto;
  cursor: pointer;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #77838f;
  margin-top: 14px;
  text-transform: uppercase;
`;

export const DisclaimerContent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #77838f;
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const BottomText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #77838f;
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

export const Link = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  color: #77838f;

  &:hover,
  &:active {
    color: #77838f;
  }
  @media ${device.mobileL} {
    font-weight: 300;
    font-size: 12px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a,
  p {
    margin-left: 21px;
  }
`;
