import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import StarAnimation from '../StarAnimation/StarAnimation';

import * as Styled from './Layout.styled';

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <main>
        <Styled.Section>
          <StarAnimation />
          <Styled.Container>{children}</Styled.Container>
        </Styled.Section>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
