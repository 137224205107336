import * as actionTypes from '../types/ui';

export const connectPopup = isOpen => {
  return {
    type: actionTypes.CONNECT_POPUP,
    isOpen,
  };
};

export const confirmPopup = isOpen => {
  return {
    type: actionTypes.CONFIRM_POPUP,
    isOpen,
  };
};
