import React from 'react';

import * as Styled from './Loader.styled';

const Loader = () => (
  <Styled.Container>
    <Styled.Spinner />
  </Styled.Container>
);

export default Loader;
