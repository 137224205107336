import * as actionTypes from '../types/wallet';
import networkManager from '../../services/network';

const initialState = {
  accounts: {},
  address: {},
  networkType: 'mainnet',
  selectedNetwork: networkManager.getNetwork('bsc', 'mainnet'),
  sourceNetwork: networkManager.getNetwork('bsc', 'mainnet'),
  destinationNetwork: networkManager.getNetwork('knstl', 'mainnet'),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONNECT_WALLET:
      return {
        ...state,
        accounts: { ...action.accounts },
      };
    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        address: { ...action.address },
      };
    case actionTypes.SET_NETWORK:
      return {
        ...state,
        selectedNetwork: action.network,
      };
    case actionTypes.SET_NETWORK_TYPE:
      return {
        ...state,
        networkType: action.netType,
      };
    case actionTypes.SET_SOURCE_NETWORK:
      return {
        ...state,
        sourceNetwork: action.sourceNetwork,
      };
    case actionTypes.SET_DESTINATION_NETWORK:
      return {
        ...state,
        destinationNetwork: action.destinationNetwork,
      };
    default:
      return state;
  }
};

export default reducer;
