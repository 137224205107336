class Network {
  constructor({
    network,
    name,
    type,
    addressType,
    chainId,
    title,
    icon,
    explorerOpts,
    tokenOpts,
    apiOpts,
    swapAddress,
  }) {
    this.network = network;
    this.addressType = addressType;
    this.name = name;
    this.type = type;
    this.chainId = chainId;
    this.title = title;
    this.icon = icon;
    this.explorerOpts = explorerOpts;
    this.tokenOpts = tokenOpts;
    this.apiOpts = apiOpts;
    this.swapAddress = swapAddress;
  }

  getNetwork() {
    return this.network;
  }

  getAddressType() {
    return this.addressType;
  }

  getType() {
    return this.type;
  }

  getName() {
    return this.name;
  }

  getTitle() {
    return this.title;
  }

  getChainId() {
    return this.chainId;
  }

  getIcon() {
    return this.icon;
  }

  getExplorerUrl() {
    return this.explorerOpts.url;
  }

  getExplorerTxUrl() {
    return `${this.explorerOpts.url}${this.explorerOpts.tx}`;
  }

  getTokenSymbol() {
    return this.tokenOpts.symbol;
  }

  getTokenName() {
    return this.tokenOpts.name;
  }

  getTokenContractAbi() {
    return this.tokenOpts.contractAbi;
  }

  getTokenContractAddr() {
    return this.tokenOpts.contractAddr;
  }

  getApiUrl() {
    return this.apiOpts.url;
  }

  getApiTxUrl(hash) {
    return `${this.apiOpts.url}${this.apiOpts.tx}${hash}`;
  }

  getSwapAddress() {
    return this.swapAddress;
  }
}

export default Network;
