import { ethers, BigNumber } from 'ethers';
import { bech32 } from 'bech32';
import axios from 'axios';
import Web3 from 'web3';

import TokenHubAbi from '../../utils/abi/TokenHubAbi';
import { TokenHubAddr } from '../../utils/constants';
import { binanceClient } from '../../services/binance';
import { delay, shift, weiUnitToDecimal } from '../../utils/helpers';
import * as actionTypes from '../types/transactions';
import { confirmPopup } from './ui';

const apiUrl = 'https://bridge-api-prod.herokuapp.com';
const postSwapOrderEndpoint = '/api/v1/swap/order';
const getSwapOrderEndpoint = '/api/v1/swap/order/';
const DECIMALS = 18;
const abiJson = [
  {"constant":true,"inputs":[{"name":"who","type":"address"}],"name":"balanceOf","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},
];

export const setFormData = form => {
  return {
    type: actionTypes.SET_FORM,
    form,
  };
};

export const setFormStep = step => {
  return {
    type: actionTypes.SET_FORM_STEP,
    step,
  };
};

export const setProcessing = processing => {
  return {
    type: actionTypes.SET_PROCESSING,
    processing,
  };
};

export const setSourceTxStatus = status => {
  return {
    type: actionTypes.SET_SOURCE_TX_STATUS,
    status,
  };
};

export const setDestinationTxStatus = status => {
  return {
    type: actionTypes.SET_DESTINATION_TX_STATUS,
    status,
  };
};

export const setSourceNetworkHash = hash => {
  return {
    type: actionTypes.SET_SOURCE_NETWORK_HASH,
    hash,
  };
};

export const setDestinationNetworkHash = hash => {
  return {
    type: actionTypes.SET_DESTINATION_NETWORK_HASH,
    hash,
  };
};

export const setErrorMessage = error => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    error,
  };
};

export const setFee = fee => {
  return {
    type: actionTypes.SET_FEE,
    fee,
  };
};

// export const estimateFeeBc = () => {
//   return async (dispatch, getState) => {
//     let feeUrl = 'https://dex.binance.org/api/v1/fees';
//     if (getState().wallet.selectedNetwork.getType() === 'testnet') {
//       feeUrl = 'https://testnet-dex.binance.org/api/v1/fees';
//     }
//     const fees = await axios.get(feeUrl).then(({ data }) => data);
//     const crossTfOutRelayFee = fees.find(
//       fee => fee.msg_type === 'crossTransferOutRelayFee'
//     )?.fee;
//     const crossTfOut = fees.find(fee => fee.msg_type === 'crossTransferOut')
//       ?.fee;
//     const fee = ((crossTfOut || 0) + (crossTfOutRelayFee || 0)) / 10 ** 8;
//     dispatch(setFee(fee));
//   };
// };

export const checkBscKnHash = txId => {
  return async dispatch => {
    let checkCount = 0;
    const interval = setInterval(async () => {
      const { data: res } = await axios.get(
        `${apiUrl}${getSwapOrderEndpoint}${txId}`
      );
      if (res.status_code !== 200) {
        checkCount += 1;
      } else if (res.state === 'order_completed') {
        clearInterval(interval);
        dispatch(setSourceTxStatus('success'));
        dispatch(setDestinationTxStatus('success'));
        dispatch(setSourceNetworkHash(res.from_user_tx_hash));
        dispatch(setDestinationNetworkHash(res.to_user_tx_hash));
      }

      if (checkCount === 1000) {
        clearInterval(interval);
        dispatch(setFormStep(0));
        dispatch(setErrorMessage(`Swap status: ${res.message}`));
        dispatch(setDestinationTxStatus('not started'));
      }
    }, 2000);
  };
};

export const transferFromKn2Bsc = () => {
  return async (dispatch, getState) => {
    dispatch(setProcessing(true));
    dispatch(setSourceTxStatus('processing'));
    dispatch(setDestinationTxStatus('pending'));

    const { amount, destination, source } = getState().transactions.form;

    const data = {
      from_address: source,
      to_address: destination,
      from_chain: 'KNSTL',
      to_chain: 'BSC',
      amount,
    };
    const { data: res } = await axios({
      method: 'post',
      url: `${apiUrl}${postSwapOrderEndpoint}`,
      data,
    }).catch(error => {
      if (error.response.data.code !== 201) {
        let errMsg = 'Failed to initiate KNSTL->BSC swap';
        if (error.response.data.message !== undefined
          && error.response.data.message.length > 0) {
          errMsg += `: ${error.response.data.message}`;
        }
        dispatch(setErrorMessage(errMsg));
        dispatch(confirmPopup(false));
        dispatch(setProcessing(false));
        dispatch(setDestinationTxStatus('not started'));
      }
    });

    dispatch(setFormStep(0.5));
    dispatch(setFee(2));
    dispatch(confirmPopup(false));
    dispatch(setProcessing(false));
    dispatch(checkBscKnHash(res.order_id));
  };
};

export const transferFromBsc2Kn = () => {
  
  return async (dispatch, getState) => {
    const darcAddress = "0x8ebC361536094fD5B4FFB8521E31900614C9F55D";
    const holderAddress = getState().wallet.address.address;
    const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    const contract = new web3.eth.Contract(abiJson, darcAddress);
    const balance = await contract.methods.balanceOf(holderAddress).call();
    const darcBalanceInWallet = weiUnitToDecimal(balance);

    try {
      dispatch(setProcessing(true));
      dispatch(setSourceTxStatus('processing'));
      dispatch(setDestinationTxStatus('pending'));

      const { amount, destination } = getState().transactions.form;
      const fromAddress = getState().wallet.address.address;

      const data = {
        from_address: fromAddress,
        to_address: destination,
        from_chain: 'BSC',
        to_chain: 'KNSTL',
        amount,
      };

      if (amount > darcBalanceInWallet) {
        dispatch(setErrorMessage('Insufficient Darc Balance'));
        dispatch(confirmPopup(false));
        dispatch(setProcessing(false));
        dispatch(setDestinationTxStatus('not started'));
        return;
      }

      const { data: res } = await axios({
        method: 'post',
        url: `${apiUrl}${postSwapOrderEndpoint}`,
        data,
      }).catch(error => {
        if (error.response.data.code !== 201) {
          let errMsg = 'Failed to initiate BSC->KNSTL swap';
          if (error.response.data.message !== undefined
            && error.response.data.message.length > 0) {
            errMsg += `: ${error.response.data.message}`;
          }
          dispatch(setErrorMessage(errMsg));
          dispatch(confirmPopup(false));
          dispatch(setProcessing(false));
          dispatch(setDestinationTxStatus('not started'));
        }
      });

      const darcContract = new ethers.utils.Interface(
        getState().wallet.sourceNetwork.getTokenContractAbi()
      );
      const encodedData = darcContract.encodeFunctionData('transfer', [
        getState().wallet.sourceNetwork.getSwapAddress(),
        String(amount).includes('.')
          ? BigNumber.from(shift(amount, DECIMALS))
          : BigNumber.from(
              String(amount).padEnd(String(amount).length + DECIMALS, '0')
            ),
      ]);
      await window.BinanceChain.request({
        jsonrpc: '2.0',
        method: 'eth_sendTransaction',
        params: [
          {
            to: getState().wallet.sourceNetwork.getTokenContractAddr(),
            data: encodedData,
            from: fromAddress,
          },
        ],
        id: 2,
      });

      dispatch(setFormStep(1));
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
      dispatch(checkBscKnHash(res.order_id));
    } catch (err) {
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
    }
  };
};

export const transferFromBc2Bsc = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setProcessing(true));
      dispatch(setSourceTxStatus('processing'));
      dispatch(setDestinationTxStatus('pending'));

      const fromAddress = getState().wallet.accounts.addresses.find(
        address =>
          address.type === getState().wallet.selectedNetwork.getAddressType()
      )?.address;
      const { amount, destination } = getState().transactions.form;

      const client = await binanceClient;
      const expireTime = Number((Date.now() / 1000).toFixed()) + 1000;

      const res = await client.bridge.transferFromBcToBsc({
        toAddress: destination, // 0xd5B86f455b54387361379e2e28f854aA2F23ac4B
        symbol: getState().wallet.selectedNetwork.getTokenSymbol(),
        fromAddress,
        amount,
        expireTime,
      });

      if (!res.result[0].hash) {
        dispatch(setProcessing(false));
        dispatch(setSourceTxStatus('failed'));
        return;
      }

      dispatch(setSourceNetworkHash(res.result[0].hash));
      dispatch(setFormStep(1));
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
      dispatch(setSourceTxStatus('success'));
    } catch (err) {
      let errMsg = 'Failed to initiate BC->BSC swap';
      if (err.message !== undefined && err.message.length > 0) {
        errMsg += `: ${err.message}`;
      }
      dispatch(setErrorMessage(errMsg));
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
    }
  };
};

export const transferFromBsc2Bc = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setProcessing(true));
      dispatch(setSourceTxStatus('processing'));
      dispatch(setDestinationTxStatus('not started'));

      const fromAddress = getState().wallet.address.address;
      const { amount, destination } = getState().transactions.form;

      const expireTime = Number((Date.now() / 1000).toFixed()) + 1000;
      const to = bech32.decode(destination);
      const toAddr = Buffer.from(bech32.fromWords(to.words)).toString('hex');

      const bep20token = new ethers.utils.Interface(
        getState().wallet.selectedNetwork.getTokenContractAbi()
      );
      const tokenHub = new ethers.utils.Interface(TokenHubAbi);

      const encodedApproveData = bep20token.encodeFunctionData('approve', [
        TokenHubAddr,
        String(amount).includes('.')
          ? BigNumber.from(String(amount * 10 ** DECIMALS))
          : BigNumber.from(
              String(amount).padEnd(String(amount).length + DECIMALS, '0')
            ),
      ]);

      await window.BinanceChain.request({
        jsonrpc: '2.0',
        method: 'eth_sendTransaction',
        params: [
          {
            to: getState().wallet.selectedNetwork.getTokenContractAddr(),
            data: encodedApproveData,
            value: '0x0',
            from: fromAddress,
          },
        ],
        id: 2,
      });

      await delay(4000);

      //   const encodedAllowanceData = bep20token.encodeFunctionData('allowance', [
      //     fromAddress,
      //     TokenHubAddr,
      //   ]);
      //   const checkAllowanceResult = await window.BinanceChain.request({
      //     jsonrpc: '2.0',
      //     method: 'eth_call',
      //     params: [
      //       {
      //         to: getState().wallet.selectedNetwork.getTokenContractAddr(),
      //         data: encodedAllowanceData,
      //         from: fromAddress,
      //       },
      //       'latest',
      //     ],
      //     id: 2,
      //   });

      const encodedTransferOutData = tokenHub.encodeFunctionData(
        'transferOut',
        [
          getState().wallet.selectedNetwork.getTokenContractAddr(),
          `0x${toAddr}`,
          String(amount).includes('.')
            ? BigNumber.from(String(amount * 10 ** DECIMALS))
            : BigNumber.from(
                String(amount).padEnd(String(amount).length + DECIMALS, '0')
              ),
          expireTime,
        ]
      );
      const transferOutResult = await window.BinanceChain.request({
        jsonrpc: '2.0',
        method: 'eth_sendTransaction',
        params: [
          {
            to: TokenHubAddr,
            data: encodedTransferOutData,
            value: '0x2386f26fc10000', // 0.01 BNB
            from: fromAddress,
          },
        ],
        id: 2,
      });
      dispatch(setFormStep(1));
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
      dispatch(setSourceTxStatus('success'));
      dispatch(setSourceNetworkHash(transferOutResult));
    } catch (err) {
      let errMsg = 'Failed to initiate BSC->BC swap';
      if (err.message !== undefined && err.message.length > 0) {
        errMsg += `: ${err.message}`;
      }
      dispatch(setErrorMessage(errMsg));
      dispatch(confirmPopup(false));
      dispatch(setProcessing(false));
    }
  };
};

export const transfer = (source, destination) => {
  return async dispatch => {
    if (source === 'bc' && destination === 'bsc') {
      dispatch(transferFromBc2Bsc());
    } else if (source === 'bsc' && destination === 'bc') {
      dispatch(transferFromBsc2Bc());
    } else if (source === 'bsc' && destination === 'knstl') {
      dispatch(transferFromBsc2Kn());
    } else if (source === 'knstl' && destination === 'bsc') {
      dispatch(transferFromKn2Bsc());
    }
  };
};
