import * as actionTypes from '../types/wallet';
import { bscConnector } from '../../services/binance';
import networkManager from '../../services/network';
import { connectPopup } from './ui';

import { isEmpty } from '../../utils/helpers';
import { setErrorMessage } from './transactions';

export const connectWallet = accounts => {
  return {
    type: actionTypes.CONNECT_WALLET,
    accounts,
  };
};

export const setAddress = address => {
  return {
    type: actionTypes.SET_ADDRESS,
    address,
  };
};

export const setNetworkType = netType => {
  return {
    type: actionTypes.SET_NETWORK_TYPE,
    netType,
  };
};

export const setNetwork = network => {
  return {
    type: actionTypes.SET_NETWORK,
    network,
  };
};

export const setSourceNetwork = sourceNetwork => {
  return {
    type: actionTypes.SET_SOURCE_NETWORK,
    sourceNetwork,
  };
};

export const setDestinationNetwork = destinationNetwork => {
  return {
    type: actionTypes.SET_DESTINATION_NETWORK,
    destinationNetwork,
  };
};

export const connectBinance = () => {
  return async (dispatch, getState) => {
    const selectedAccount = await bscConnector.getAccount();
    const bcw = await bscConnector.getProvider();
    const accs = await bcw.requestAccounts();
    const acc = accs.find(ac =>
      ac.addresses.find(addr => addr.address === selectedAccount)
    );
    const address = acc.addresses.find(
      addr => addr.type === getState().wallet.selectedNetwork.getAddressType()
    );
    dispatch(connectWallet(acc));
    dispatch(setAddress(address));
    dispatch(connectPopup(false));
  };
};

export const changeChainId = chainId => {
  return async (dispatch, getState) => {
    const network = networkManager.getNetworkByChainId(chainId);
    if (!network) {
      dispatch(
        setErrorMessage(
          'Bridge doesn`t support the Ethereum chain. Please change it to another one'
        )
      );
    }
    if (network) {
      const netType = network.getType();
      if (!isEmpty(getState().wallet.accounts)) {
        const address = getState().wallet.accounts.addresses.find(
          addr => addr.type === network.getAddressType()
        );
        dispatch(setAddress(address));
      }
      dispatch(setNetwork(network));
      dispatch(setNetworkType(netType));
    }
  };
};

export const changeSourceNetwork = net => {
  return async (dispatch, getState) => {
    const type = getState().wallet.networkType;
    const newNetwork = networkManager.getNetwork(net, type);

    if (
      !isEmpty(getState().wallet.accounts) &&
      getState().wallet.selectedNetwork.getNetwork() !== net
    ) {
      if (net !== 'knstl')
        await window.BinanceChain.switchNetwork(newNetwork.getName());
    }

    if (isEmpty(getState().wallet.accounts)) {
      dispatch(setNetwork(newNetwork));
    }

    dispatch(setSourceNetwork(newNetwork));
  };
};

export const changeDestinationNetwork = net => {
  return async (dispatch, getState) => {
    const type = getState().wallet.networkType;
    const newNetwork = networkManager.getNetwork(net, type);
    dispatch(setDestinationNetwork(newNetwork));
  };
};

export const switchNetworkType = source => {
  return async (dispatch, getState) => {
    const currentNetType = getState().wallet.selectedNetwork.getType();
    const newNetwork = networkManager.getNetwork(source, currentNetType);
    await window.BinanceChain.switchNetwork(newNetwork.getName());
    dispatch(setNetworkType(newNetwork.getType()));
    dispatch(setNetwork(newNetwork));
  };
};
