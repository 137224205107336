import React from 'react';
import { connect } from 'react-redux';

import * as uiActions from '../../store/actions/ui';
import { cutString, isEmpty, getIconByAddressType } from '../../utils/helpers';

import * as Styled from './Header.styled';
import logo from '../../assets/logo.svg';

const Header = ({ isAddress, address, onConnectPopup }) => {
  const image = getIconByAddressType(address.type);
  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.Logo>
          <Styled.LogoLink onClick={() => window.location.reload()}>
            <img src={logo} alt="logo" />
            <Styled.NameWrapper>
              <Styled.Title>Konstellation</Styled.Title>
              <Styled.SubTitle>Bridge</Styled.SubTitle>
            </Styled.NameWrapper>
          </Styled.LogoLink>
        </Styled.Logo>
        <Styled.WalletAddress isConnected={isAddress}>
          {isAddress && (
            <Styled.Wrapper>
              {image && <img src={image} alt="icon" />}
              <Styled.Adress>{cutString(address.address)}</Styled.Adress>
            </Styled.Wrapper>
          )}
          {!isAddress && (
            <Styled.Button onClick={() => onConnectPopup(true)}>
              Connect Wallet
            </Styled.Button>
          )}
        </Styled.WalletAddress>
      </Styled.Container>
    </Styled.Header>
  );
};

const mapStateToProps = state => {
  return {
    isAddress: !isEmpty(state.wallet.address),
    address: state.wallet.address,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onConnectPopup: isOpen => dispatch(uiActions.connectPopup(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
