import styled, { keyframes } from 'styled-components';

const spin = keyframes`
0% { 
    transform: rotate(0deg);
 }
100% { 
    transform: rotate(360deg); 
}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top-color: #38c6f4;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spin} 2s linear infinite;
  margin: 150px auto;
`;
