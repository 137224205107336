import { ethers, BigNumber } from 'ethers';
import BSCN from '../assets/BSCN-icon.svg';
import BCN from '../assets/BCN-icon.svg';

export const cutString = string => {
  if (!string) {
    return ``;
  }
  const { length } = string;
  const md = length / 2;

  return `${string.slice(0, md - 15)}...${string.slice(md + 15, length)}`;
};

export const getIconByAddressType = addType => {
  let icon;
  if (addType === 'eth') {
    icon = BSCN;
  }

  if (addType === 'bbc-mainnet' || addType === 'bbc-testnet') {
    icon = BCN;
  }
  return icon;
};

export const putStringOnNewLine = string => {
  if (!string) {
    return ``;
  }

  const md = string.length / 2;
  return `${string.substring(0, md)}\n${string.substring(md)}`;
};

export const putStringOnNextLines = string => {
  if (!string) {
    return ``;
  }

  const i = string.length / 3;
  return `${string.substring(0, i)}\n${string.substring(
    i,
    2 * i
  )}\n${string.substring(2 * i)}`;
};

export const isEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const parseAmount = value => {
  if (value < 0) {
    return 0;
  }
  if (value.length > 0) {
    const splitted = value.includes('.') ? value.split('.') : [value, ''];
    splitted[1] =
      splitted[1].length > 5 ? splitted[1].substr(0, 5) : splitted[1];
    return value.includes('.') ? `${splitted[0]}.${splitted[1]}` : value;
  }
  return 0;
};

export const validateKNSTLAmount = value => {
  return value < 3;
};

export const weiUnitToDecimal = value => {
  const weiValue = BigNumber.from(value);
  return Number(ethers.utils.formatEther(weiValue));
}

export const shift = (n, shiftAmount) => {
  const num = String(n);
  const dotIdx = num.indexOf('.');
  if (dotIdx === -1) {
    return num + '0'.repeat(shiftAmount);
  }
  const curDecimals = num.length - dotIdx - 1;
  return num.replace('.', '') + '0'.repeat(shiftAmount - curDecimals);
};
