import React, { useState, useEffect } from 'react';

import arrowDownLight from '../../assets/disclaimer-arrow-down.svg';
import arrowUpLight from '../../assets/disclaimer-arrow-up.svg';

import * as Styled from './Footer.styled';

const Footer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const year = new Date().getFullYear();

  useEffect(() => {
    if (showDisclaimer) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showDisclaimer]);

  return (
    <Styled.Footer>
      <Styled.Container>
        <Styled.DisclaimerWrapper>
          <Styled.DisclaimerBtn
            onClick={() => setShowDisclaimer(prev => !prev)}
          >
            {showDisclaimer ? (
              <img src={arrowDownLight} alt="arrow-down" />
            ) : (
              <img src={arrowUpLight} alt="arrow-up" />
            )}
          </Styled.DisclaimerBtn>
        </Styled.DisclaimerWrapper>
        <Styled.Text>DISCLAIMER</Styled.Text>
        {showDisclaimer && (
          <Styled.DisclaimerContent>
            This website is a publication of Konstellation Korea LTD
            (&quot;Konstellation&quot;). Information presented is believed to be
            factual and up-to-date, but is not a guarantee of its accuracy and
            it should not be regarded as a complete analysis of the subjects
            discussed. All expressions of opinion reflect the judgment of the
            authors as of the date of publication and are subject to change.
            Information on this website does not involve the rendering of
            personalized investment advice or any offer to buy or sell, or a
            solicitation, or any offer to buy or sell any securities or
            financial products. Hyperlinks on this website are provided as a
            convenience and Konstellation disclaims any responsibility for
            information, services or products found on websites linked hereto.
            The cryptocurrency industry is highly volatile and may represent
            significant risks
          </Styled.DisclaimerContent>
        )}
        <Styled.Wrapper>
          <Styled.BottomText>
            © {year} Konstellation. All rights reserved.
          </Styled.BottomText>
          <Styled.LinkWrapper>
            <Styled.Link>Privacy Policy</Styled.Link>
            <Styled.Link>Terms & Conditions</Styled.Link>
          </Styled.LinkWrapper>
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.Footer>
  );
};

export default Footer;
