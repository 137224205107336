import BSCN from '../assets/BSCN-icon.svg';
import KNST from '../assets/KNST-icon.svg';
// import BCN from '../assets/BCN-icon.svg';

export const BC = 'bc';
export const BSC = 'bsc';
export const KNSTL = 'knstl';

export const TokenHubAddr = '0x0000000000000000000000000000000000001004';

const network = [
  // {
  //   id: BC,
  //   name: 'Binance Chain Network',
  //   icon: BCN,
  //   addressType: 'bbc-mainnet',
  // },
  {
    id: BSC,
    name: 'Binance Smart Chain Network',
    icon: BSCN,
    addressType: 'eth',
  },
  {
    id: KNSTL,
    name: 'Konstellation Network',
    icon: KNST,
    addressType: 'knstl',
  },
];

export default network;
