class NetworkManager {
  constructor() {
    this.networks = [];
  }

  registerNetwork(network) {
    this.networks.push(network);
  }

  getNetworkByChainId(chainID) {
    const net = this.networks.find(n => n.chainId === chainID);
    return net;
  }

  getNetworkName(network, type) {
    return this.getNetwork(network, type).getName();
  }

  getNetwork(network, type) {
    const netw = this.networks.find(
      n => n.network === network && n.type === type
    );
    return netw;
  }

  getSupportedNetworks() {
    return this.networks;
  }
}

export default NetworkManager;
