import styled from 'styled-components';
import star from '../../assets/star-bg.svg';

export const Section = styled.section`
  position: relative;
  width: 100vw;
  min-height: 450px;
  margin-top: -90px;
  padding-top: 90px;

  &:after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, #010101 -19.75%, #21315c 64.06%);
    height: 400px;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    width 100vw;
    height: 300px;
    top: 0;
    right: 0;
    background-image: url(${star});
    z-index: -1;
  }
  
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 4%;
`;
